import React from 'react'
import Index from '../components/about'
const about = () => {
  return (
    <div>
      <Index />
    </div>
  )
}

export default about
