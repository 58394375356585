import React from "react";
import { leaderShipData } from "../../constants/Data";
const Leadership = () => {
  return (
    <div className="w-full bg-white">
      {/* <div className="max-w-7xl mx-auto px-10 md:px-20 py-20">
        <h1 className="text-black text-xl title title pb-3">Our leadership</h1>
        <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {leaderShipData.map((item, index) => {
            return (
              <div key={index}>
                <img src={item.picture} alt={item.userName} className='mb-4'/>
                <h1 className='text-black text-base  font-bold'>{item.userName}</h1>
                <p className='text-black text-lg pb-4 title font-semibold'>{item.designation}</p>
                <p className=' text-primary text-lg leading-6 pb-4 title font-normal'>{item.description}</p>
                <p className=' text-primary text-lg leading-6 pb-4 title font-normal'>{item.descriptionB}</p>
              </div>
            );
          })}
        </div>
      </div> */}
      <div className="max-w-7xl mx-auto px-10 md:px-20 py-20">
        <div className="text-black text-2xl title title pb-3">Our Story</div>
        <div>
          <p className=' text-primary text-lg leading-6 pb-4 title font-normal'>
            Founded out of a decade-long experience in building cutting-edge
            solutions for political committees, campaigns, and organizations,
            Astra Intel was born from a need for precision and efficiency in the
            ever-evolving world of marketing. The 2022 advocacy industry’s
            fundraising slump highlighted the need for more sustainable,
            cost-effective data infrastructure. Astra Intel was created to fill
            that gap, offering a permanent solution that delivers best-in-class
            marketing data technology.
          </p>
          <p className=' text-primary text-lg leading-6 pb-4 title font-normal'>
            Combined with the early adoption of the latest data engineering and
            AI technology, we’ve had success bringing new levels of value to our
            clients and giving them the latest competitive edge in advocacy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
