import React from 'react'
import Layout from "../../Layout";
import Header from "./Header";
import Guiding from "./Guiding";
import Leadership from "./Leadership";
const Index = () => {
    return (
        <Layout>
            <Header />
            <Guiding />
            <Leadership />
        </Layout>
    )
}

export default Index
