import React from "react";

const Header = () => {
  return (
    <div className="header-about">
      <div className="max-w-7xl mx-auto px-10 md:px-20">
        <h1 className="title text-black text-5xl md:text-6xl  pt-48 pb-4">
          Specialized Team
        </h1>
        <h1 className="title text-black text-5xl md:text-6xl  pb-8">
          of Data Professionals{" "}
        </h1>
        <p className=" h-1 bg-blue-500 w-24 mb-14"></p>
        <p className="title text-xl text-black max-w-md">
          Enterprise-Grade Expertise, Tailored to Your Mission
        </p>
      </div>
    </div>
  );
};

export default Header;
