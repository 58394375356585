import React from "react";

const Guiding = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-7xl mx-auto px-10 md:px-20 pt-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
          <div>
            <h1 className="text-black text-xl title title pb-3">
              {/* Our guiding principles */}Our Principles
            </h1>
            <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
            <p className="text-primary unlock-title  pb-8">
              {/* We harness data, analytics and design to give organisations a
              clear path to improve performance. */}
              Every action is an opportunity to improve on the road to
              perfection.
            </p>
            {/* <p className="text-primary unlock-title  pb-8">
              When we combine our skills and approach with McKinsey & Company’s
              knowledge and expertise, we equip clients with an incomparable
              level of competitiveness.
            </p> */}
          </div>
          <div>
            <div className="mb-10">
              <h1 className="text-black text-xl font-semibold  pb-3">
                {/* Our guiding principles */}
                Accuracy & Security First
              </h1>
              <p className="text-primary text-base  pb-8 title">
                {/* When we combine our skills and approach with McKinsey &
                Company’s knowledge and expertise, we equip clients with an
                incomparable level of competitiveness. */}
                Data is only as valuable as its accuracy and privacy. We
                recognize the immense value of your data and are committed to
                safeguarding it with the highest standards of security and
                precision.
              </p>
            </div>
            <div className="mb-10">
              <h1 className="text-black text-xl font-semibold  pb-3">
                {/* Transformational thinking */}
                Challenge The Limits
              </h1>
              <p className="text-primary text-base  pb-8 title">
                {/* The real power of analytics is embedding them into the operating
                model of an organisation. */}
                Innovation is in our DNA. We thrive on pushing the boundaries
                and aren't afraid to voice new ideas that can elevate your
                campaign. With Astra Intel, you're not just getting a service
                provider—you're gaining a partner who is invested in your
                success.
              </p>
            </div>
            {/* <div className="mb-10">
              <h1 className="text-black text-xl font-semibold  pb-3">
                Our guiding principles
              </h1>
              <p className="text-primary text-base title  pb-4">
                When we combine our skills and approach with McKinsey &
                Company’s knowledge and expertise, we equip clients with an
                incomparable level of competitiveness.
              </p>
              <p className="text-primary text-base title  pb-4">
                Our advanced analytics capabilities combined with McKinsey’s
                deep domain expertise and change management experience equip us
                with a powerful lens to evaluate and improve every facet of an
                organisation.
              </p>
            </div>
            <div className="mb-10">
              <h1 className="text-black text-xl font-semibold  pb-3">
                Capability building
              </h1>
              <p className="text-primary title text-base  pb-4">
                To diagnose challenges and prioritise actions, we focus on
                capability building-not just the models. We follow a proven
                five-step protocol grounded in McKinsey’s decades of strategy
                work as well as our unique approach to discovering and
                exploiting data-driven insights.
              </p>
              <p className="text-primary title text-base  pb-4">
                This is formed of: Ideation, Intelligence, Inception,
                Intervention, and Independence.
              </p>
            </div> */}
          </div>
          <div>
            <div className="mb-10">
              <h1 className="text-black text-xl font-semibold  pb-3">
                {/* Trusted partnership */}
                Aligning Technology with Your Goals
              </h1>
              <p className="text-primary title text-base  pb-4">
                {/* Data is the lifeblood of any modern organisation, and trust is
                the currency of our engagements with clients. */}
                We believe technology should serve your mission, not the other
                way around. Using agile methodologies, we ensure that our
                solutions are perfectly aligned with your goals through
                continuous feedback loops to maximize value.
              </p>
              {/* <p className="text-primary title text-base  pb-4">
                We strive to maintain the highest levels of data handling,
                governance and information security with our clients. Nothing is
                more important to us.
              </p> */}
            </div>
            <div className="mb-10">
              <h1 className="text-black text-xl font-semibold  pb-3">
                {/* Boundary-breaking */}
                Continuous Improvement
              </h1>
              <p className="text-primary title text-base  pb-4">
                {/* We believe that the most valuable insights happen at the
                boundaries of different disciplines, data, and techniques. */}
                At Astra Intel, we believe the journey to excellence is ongoing.
                As we enhance your data infrastructure, we also invest in your
                team's growth, fostering a culture of learning and collaboration
                between organizations.
              </p>
              {/* <p className="text-primary title text-base  pb-4">
                Our client work focuses on combining data expertise with human
                judgement to reimagine and rework how a business can operate in
                the future.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guiding;
